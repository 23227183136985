export default [
  {
    value: 'Model A',
    label: 'Model A',
  },
  {
    value: 'Model C',
    label: 'Model C',
  },
  {
    value: 'Model D',
    label: 'Model D',
  },
  {
    value: 'Model AL',
    label: 'Model AL',
  },
  {
    value: 'Model CL',
    label: 'Model CL',
  },
  {
    value: 'Model O',
    label: 'Model O',
  },
  {
    value: 'Model AD',
    label: 'Model AD',
  },
  {
    value: 'Model A4LT',
    label: 'Model A4LT',
  },
  {
    value: 'Model A4LT-L',
    label: 'Model A4LT-L',
  },
  {
    value: 'Model ADF',
    label: 'Model ADF',
  },
];

export const models_to_avoid = ['Model C', 'Model AL', 'Model CL', 'Model A4LT', 'Model A4LT-L']